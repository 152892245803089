require('bootstrap');

$(document).ready(function(){
  $('.fade').slick({
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    infinite: true,
    speed: 800,
    fade: true,
    cssEase: 'linear'
  });
});